import { Trans, useTranslation } from "react-i18next";

import { Link, Typography, useToast } from "@aviary";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { gRecaptchaExecute } from "@shared/utils/gRecaptchaExecute/gRecaptchaExecute";
import { SignInWrapper } from "@unauthenticated/shared/components";
import { TakeMeToGmailButton } from "@unauthenticated/signupSignin/components/TakeMeToGmailButton";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import { ReturnToSignInButton } from "../../ReturnToSignInButton/ReturnToSignInButton";
import { useUserForgotPasswordEmail } from "../UserForgotPasswordEmail.mutation";

import * as styles from "./PasswordSentPage.styles";

interface Props {
  email: string;
}

const PasswordSentPage = ({ email }: Props) => {
  const { t } = useTranslation();
  const { makeToast } = useToast();
  const { storePlatform } = useSharedGlobalConfig();

  const [requestNewPassword] = useUserForgotPasswordEmail({
    onCompleted: completedData => {
      if (completedData?.auth?.userForgotPasswordEmail?.emailSent) {
        makeToast("success", t(l.signIn.EmailSentAgain));
      }
    },
    onError: ({ message }) => {
      makeToast("error", message);
    },
  });

  const handleClick = () =>
    gRecaptchaExecute(captchaToken =>
      requestNewPassword({
        variables: {
          input: {
            captchaToken,
            attributes: {
              email,
              storePlatform,
            },
          },
        },
      })
    );

  return (
    <SignInWrapper css={styles.textCenter}>
      <Typography type="h3">{t(l.practitionerSignUp.PleaseCheckInbox)}</Typography>
      <Typography>
        <Trans i18nKey={l.signIn.IfEmailExists}>
          If the account <b css={styles.emailEmphasis}>{{ email }}</b> exists, you will receive an
          email with instructions on how to set a new password.
        </Trans>
      </Typography>
      <TakeMeToGmailButton css={styles.takeMeToGmailBtn} email={email} />
      <Typography>
        <Trans i18nKey={l.signIn.DidNotGetEmail}>
          Didn't get the email? Check your spam folder or
          <Link onClick={handleClick}>resend</Link>.
        </Trans>
      </Typography>
      <ReturnToSignInButton />
    </SignInWrapper>
  );
};

export { PasswordSentPage };
