import { useTranslation } from "react-i18next";

import {
  Button,
  ButtonGroup,
  DropdownButton,
  DropdownContent,
  DropdownLink,
  Dropdown,
} from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { useSharedGlobalConfig } from "@shared/hooks/useSharedGlobalConfig/useSharedGlobalConfig";
import { authRoutes } from "@unauthenticated/shared/data/authRoutes";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

import * as styles from "./CountrySelect.styles";

const CountrySelect = () => {
  const { isCanada } = useSharedGlobalConfig();
  const { t } = useTranslation();
  const { tablet } = useBreakpoints();

  const getUSButtonStyle = [!isCanada && styles.activeButton];
  const getCAButtonStyle = [isCanada && styles.activeButton];

  if (tablet.lessThan) {
    return (
      <Dropdown>
        <DropdownButton isText aria-label={isCanada ? t(l.common.CALogin) : t(l.common.USLogin)}>
          {isCanada ? <>🇨🇦 CA</> : <>🇺🇸 US</>}
        </DropdownButton>
        <DropdownContent>
          <DropdownLink aria-label={t(l.common.GoToUS)} href={authRoutes.usApp}>
            🇺🇸 US
          </DropdownLink>
          <DropdownLink aria-label={t(l.common.GoToCA)} href={authRoutes.caApp}>
            🇨🇦 CA
          </DropdownLink>
        </DropdownContent>
      </Dropdown>
    );
  }

  return (
    <ButtonGroup>
      <Button
        href={authRoutes.usApp}
        isColor="system"
        css={getUSButtonStyle}
        aria-label={t(l.common.GoToUS)}
      >
        🇺🇸 US
      </Button>
      <Button
        href={authRoutes.caApp}
        isColor="system"
        css={getCAButtonStyle}
        aria-label={t(l.common.GoToCA)}
      >
        🇨🇦 CA
      </Button>
    </ButtonGroup>
  );
};

export { CountrySelect };
