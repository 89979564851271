import { Typography } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";

import { title as titleStyles } from "./SignUpTitle.styles";

interface Props {
  title: string;
}

const SignUpTitle = ({ title }: Props) => {
  const { phoneLarge } = useBreakpoints();

  return (
    <Typography type="h1" sizeOverride={phoneLarge.greaterThan ? "h2" : "h4"} css={titleStyles}>
      {title}
    </Typography>
  );
};

export { SignUpTitle };
