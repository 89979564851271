import { useTranslation } from "react-i18next";

import { Button } from "@aviary";
import { useBreakpoints } from "@shared/hooks/useBreakpoints/useBreakpoints";
import { l } from "@unauthenticated/signupSignin/locales/i18n";

interface Props {
  email: string;
}

const GMAIL_SUFFIX = "@gmail.com";

const TakeMeToGmailButton = ({ email, ...rest }: Props) => {
  const { t } = useTranslation();

  const isEmailAGmail = email.endsWith(GMAIL_SUFFIX);

  const { tablet } = useBreakpoints();
  if (!isEmailAGmail) return null;

  return (
    <Button
      isFullWidth={!tablet.greaterThan}
      href="https://mail.google.com"
      target="_blank"
      rel="noopener noreferrer"
      {...rest}
    >
      {t(l.practitionerSignUp.TakeMeToGmail)}
    </Button>
  );
};

export { TakeMeToGmailButton };
