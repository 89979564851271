import { css, type Theme } from "@emotion/react";

import { animations, helpers } from "@styles";

const activeStyles = (theme: Theme) => css`
  &[aria-current] {
    color: ${theme.success.textBase};
  }
`;

export const dropdownLink = (theme: Theme) => css`
  ${animations.transition()}
  transform-style: unset;

  cursor: pointer;
  font-size: ${theme.aviaryTypography.body.fontSize};
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  &&& {
    color: ${theme.text.body};

    &:hover,
    &:focus,
    &:focus-within {
      color: ${theme.text.body};
    }
  }
`;

export const disabled = (theme: Theme) => css`
  cursor: not-allowed;
  color: ${theme.disabled.text};
  background-color: ${theme.disabled.background};

  a {
    cursor: not-allowed;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    color: ${theme.disabled.text};
    background-color: ${theme.disabled.background};
  }
`;

export const navLink = (noActiveStyling?: boolean) => (theme: Theme) => css`
  display: block;
  padding: 0.5rem 0.5rem;
  color: ${theme.text.body};
  width: 100%;
  line-height: 1.125rem;
  ${animations.transition()}
  transform-style: unset;
  border: 1px solid transparent;
  border-radius: 4px;
  ${helpers.ellipsesOverflowHelper}

  &:hover {
    background-color: ${theme.system.backgroundMuted};
    color: ${theme.text.emphasis};
  }
  &:active {
    color: ${theme.text.emphasis};
    background: ${theme.system.backgroundMutedActive};
  }
  &:focus {
    color: ${theme.text.emphasis};
    background: ${theme.surface.interactiveBackground};
    border: 1px solid ${theme.input.borderActive};
  }

  ${!noActiveStyling && activeStyles(theme)}
`;

export const externalIcon = css`
  margin-left: 0.25rem;
  margin-right: 0.125rem;
`;
